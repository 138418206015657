/* eslint-disable import/no-unresolved */
import { useMemo } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '@nxweb/auth';
import { ArrowBarDown, Bell } from '@nxweb/icons/bootstrap';
import { ChevronRight, Power } from '@nxweb/icons/feather';

import { Avatar } from '@components/avatar';

import { USER_DROPDOWN_ITEMS } from './navbar-user';

const UserDropdown = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const auth = useAuth();
  const loggedIn = auth.data?.loggedIn;
  const user = auth.data?.user;

  /*
   * Const { data: auth, ...api } = useAuth();
   * const user = useMemo(() => auth?.user, [auth]);
   * const loggedIn = auth?.loggedIn;
   */

  return (
    <>
      {loggedIn
        ? <div>
          <Dropdown as="li" className="dropdown-user nav-item w-100 navbar-dropdown-override">
          <Dropdown.Toggle as="a" className="nav-link dropdown-user-link d-flex">
              <div
                className="me-2"
              >
                <Bell />
              </div>
              {user?.picture
                ? <Avatar className="me-1" img={user?.picture} imgHeight="40" imgWidth="40" />
                : <Avatar className="me-1" color="light-secondary" content={user?.name || 'Guest User'} initials={true} />}
              <div className="user-nav d-sm-flex d-none align-self-center">
                <span
                  className="text-btn-1 fw-bold me-2"
                >
                  {user?.name || 'Guest User'}
                </span>
                <ArrowBarDown className="align-self-center" fill="#646464" size={16} />
              </div>

          </Dropdown.Toggle>
          {loggedIn
            ? <Dropdown.Menu align="end">
                {USER_DROPDOWN_ITEMS.map((o) => {
                  return (
                    <Dropdown.Item className="d-flex align-items-center" disabled={o.disabled} href={o.to} key={o.title}>
                      {o.icon}
                      <span className="align-middle">{o.title}</span>
                    </Dropdown.Item>
                  );
                })}
                <Dropdown.Item className="d-flex align-items-center text-danger" onClick={() => auth.logout()}>
                  <Power size={14} />
                  <span>Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            : null}
          </Dropdown>
          </div>
        : <div>
          <Button className="masuk me-1" href="/oauth2/authorize" variant="outline-dark">Masuk</Button>
          <Button className="daftar" variant="secondary" onClick={() => navigate('/daftar')}>Daftar <ChevronRight size={24} /></Button>
          </div>}
    </>
  );
};

UserDropdown.displayName = 'UserDropdown';
export { UserDropdown };
