interface KarirDataModel {
  'CAREER_TAG': string
  'COMPANY_NAME': string
  'CONTENT': string
  'CREATED_AT': string
  'CREATED_BY': string
  'DELETED_AT': string
  'DELETED_BY': string
  'DESCRIPTION': string
  'ID': number
  'IMAGE': string
  'IS_HEADER': boolean
  'LOCATION': string
  'SALARY_MAX': string
  'SALARY_MIN': string
  'TITLE': string
  'UPDATED_AT': string
  'UPDATED_BY': string
  'WORKING_TIME': string
  'WORKING_TYPE': string
}

export interface KarirModel {
  detail: KarirDataModel
  list: {
    output: KarirDataModel[]
    total: 0
  }
}

export enum KarirActionType {
  GetKarirList = 'Karir➝GetKarirList',
  GetKarirById = 'Karir➝GetKarirById'
}

export type KarirAction = {
  data: {
    output: KarirDataModel[]
    total: number
  }
  type: KarirActionType.GetKarirList
} | {
  data: KarirDataModel
  type: KarirActionType.GetKarirById
};
