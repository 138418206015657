export enum AppActionType {
  Load
}

export interface AppModel {
}

export interface AppAction {
  type: AppActionType.Load
  data: AppModel
}
