interface Jobs {
  CONTENT: string
  DESCRIPTION: string
  ID: number
  IMAGE: string
  TITLE: string
}

export interface JobsModel {
  jobsList: Jobs[]
}

export enum JobsActionType {
  GetJobs = 'Jobs➝ GetJobs'
}
export interface JobsAction {
  type: JobsActionType.GetJobs
  data: Jobs[]
}
