/* eslint-disable import/exports-last */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable import/no-unresolved */
import type { FC } from 'react';
import { Button, Col, Collapse, Container, Nav, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from '@nxweb/auth';
import { Cart, ChevronRight, Person, Power, Wallet } from '@nxweb/icons/bootstrap';

import { IconHamburger } from '@components/icon';

import { UserDropdown } from './user-dropdown';

import mainLogo from '@assets/logo/logo-black 1.svg';

interface NavbarUserProps {
  openNavbar?: boolean
  toggleOpenNavbar?: () => void
}

const EMPTY_FUNC = () => { };

const scrollToFooter = () => {
  const footerElement = document.getElementById('footer');

  footerElement?.scrollIntoView({ behavior: 'smooth' });
};

const navList = [
  {
    text: 'Beranda',
    to: '/'
  },
  {
    text: 'Kolaborasi',
    to: '/kolaborasi'
  },
  {
    text: 'Program',
    to: '/program'
  },
  {
    text: 'Karir',
    to: '/karir'
  },
  {
    text: 'Event',
    to: '/event'
  },
  {
    text: 'Artikel',
    to: '/artikel'
  },
  {
    text: 'Tentang',
    to: scrollToFooter
  }
];

export const USER_DROPDOWN_ITEMS = [
  {
    icon: <Cart size={14} />,
    title: 'Pesanan',
    to: '/#'
  },
  {
    disabled: true,
    icon: <Wallet size={14} />,
    title: 'Kantong Pengembalian',
    to: '/#'
  },
  {
    icon: <Person size={14} />,
    title: 'Profile',
    to: '/#'
  }
];

const NavbarUser: FC<NavbarUserProps> = ({ openNavbar = false, toggleOpenNavbar = EMPTY_FUNC }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const loggedIn = auth?.data?.loggedIn;

  return (
    <Container className="d-inline">
      <Row>
        <Col className="d-flex justify-content-center align-items-center" md={3} xs="auto">
          <Link to="/">
            <img alt="" src={mainLogo} />
          </Link>
        </Col>
        <Col className="d-none d-lg-flex justify-content-center align-items-center" xs="auto">
          <Nav.Item>
            <ul className="nav navbar-nav align-items-center gap-50">
              {navList?.map((o) => {
                return (
                  <Nav.Item key={o.text}>
                    <Link
                      className="nav-link"
                      to={typeof o.to === 'string' ? o.to : '#'}
                      onClick={() => (typeof o.to !== 'string' ? o.to() : {})}
                    >
                      <span className="navbar-item-spacing">{o.text}</span>
                    </Link>
                  </Nav.Item>
                );
              })}
            </ul>
          </Nav.Item>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <Nav.Item className="d-none d-lg-block">
            <UserDropdown />
          </Nav.Item>
          <Nav.Item className="d-block d-lg-none">
            <IconHamburger
              aria-controls="collapsable-navbar"
              aria-expanded={openNavbar}
              className="text-bg-dark"
              fill="primary"
              size={24}
              src=""
              onClick={toggleOpenNavbar} />
          </Nav.Item>
        </Col>
      </Row>
      <Collapse in={openNavbar}>
        <div>
          <ul className="nav navbar-nav collapsable-navbar align-items-center gap-50 pb-0" id="collapsable-navbar">
            {navList?.map((o) => {
              return (
                <Nav.Item key={o.text}>
                  <Link
                    className="nav-link"
                    to={typeof o.to === 'string' ? o.to : '#'}
                    onClick={() => (typeof o.to !== 'string' ? o.to() : {})}
                  >
                    <span className="navbar-item-spacing">{o.text}</span>
                  </Link>
                </Nav.Item>
              );
            })}
          </ul>
          {loggedIn
            ? <>
              <hr />
              <ul className="nav navbar-nav collapsable-navbar align-items-center gap-1 pt-0" id="collapsable-navbar">
                {USER_DROPDOWN_ITEMS.map((o) => {
                  return (
                    <Nav.Item key={o.title}>
                      <Link
                        className="nav-link"
                        to={o.to}
                      >
                        {o.icon}
                        <span className="navbar-item-spacing">{o.title}</span>
                      </Link>
                    </Nav.Item>
                  );
                })}
                <Nav.Item>
                  <Link
                    className="nav-link"
                    to="/#"
                    onClick={() => auth.logout()}
                  >
                    <Power className="text-danger" size={14} />
                    <span className="navbar-item-spacing text-danger">Logout</span>
                  </Link>
                </Nav.Item>
              </ul>
              </>
            : <>
            <hr />
            <div>
          <Button className="masuk me-1" href="/oauth2/authorize" variant="outline-dark">Masuk</Button>
          <Button className="daftar" variant="secondary" onClick={() => navigate('/daftar')}>Daftar <ChevronRight size={24} /></Button>
            </div>
              </>}
        </div>
      </Collapse>
    </Container>
  );
};

NavbarUser.displayName = 'NavbarUser';

export { NavbarUser };
export type { NavbarUserProps };
