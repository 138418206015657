export interface Banner {
  ACTIVE: boolean
  DESCRIPTION: string
  ID: number
  IMAGE_DESKTOP: string
  IMAGE_MOBILE: string
  TITLE: string
}

export interface BannerModel {
  bannerList: Banner[]
}

export enum BannerActionType {
  GetBanner = 'Banner➝ GetBanner'
}
export interface BannerAction {
  data: Banner[]
  type: BannerActionType.GetBanner
}
