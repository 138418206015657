import { apiFetch } from '@api/base';
import type { TAction, TDispatch } from '@models/types';

import { InitActionType } from './types';

import type { InitAction, InitModel } from './types';

export const InitDefault: InitModel = {
  allCategories: {
    data: [],
    total: 0
  },
  allApplications: {
    data: [],
    total: 0
  }
};

export const InitReducer = (state: InitModel = InitDefault, action: InitAction): InitModel => {
  switch (action.type) {
    case InitActionType.GetAllCategories:
      return { ...state, allCategories: action.data };
    case InitActionType.GetAllApplications:
      return { ...state, allApplications: action.data };

    default:
      return { ...state };
  }
};

export const InitCommand = {
  getInitCategories: (params: unknown): TAction<InitAction, string> => {
    return (dispatch: TDispatch<InitAction>) => {
      const headers = {
        // Authorization: getState().auth.userToken
      };

      return apiFetch().get('/api/definitions-categories', { headers, params })
        .then((response: unknown) => {
          if (response.status === 200) {
            dispatch({
              type: InitActionType.GetAllCategories,
              data: response.data
            });
          }

          return 'ok';
        }).catch((err: unknown) => {
          console.debug(err);

          return 'err';
        });
    };
  },
  getInitApplications: (params: unknown): TAction<InitAction, string> => {
    return (dispatch: TDispatch<InitAction>) => {
      const headers = {
        // Authorization: getState().auth.userToken
      };

      return apiFetch().get('/api/definitions', { headers, params })
        .then((response: unknown) => {
          if (response.status === 200) {
            dispatch({
              type: InitActionType.GetAllApplications,
              data: response.data
            });
          }

          return 'ok';
        }).catch((err: unknown) => {
          console.debug(err);

          return 'err';
        });
    };
  }
};
