interface AboutDataModel {
  'ABOUT_US': string
  'ADDRESS': string
  'ADDRESS_URL': string
  'CONTACT_US': string
  'EMAIL': string
  'FACEBOOK': string
  'INSTAGRAM': string
  'LINKEDIN': string
  'PHONE_NUMBER': string
  'TWITTER': string
  'WHATSAPP': string
  'YOUTUBE': string
}

interface CoCreationDataModel {
  'CREATED_AT': string
  'DELETED_AT': string
  'DESCRIPTION': string
  'ID': number
  'IMAGE': string
  'NAME': string
  'TYPE': string
  'UPDATED_AT': string
}

interface GalleryDataModel {
  'DESCRIPTION': string
  'FILE_LOCATION': string
  'GALLERY_TAG': string
  'ID': number
  'IS_ACTIVE': boolean
  'PINNED': boolean
  'TITLE': string
}

interface HeadlineDataModel {
  'CONTENT_DESCRIPTION': string
  'CONTENT_MEDIA': string
  'CONTENT_TITLE': string
  'CONTENT_TYPE': string
  'ID': number
  'LINK': string
  'MEDIA_TYPE': string
}

interface ProgramBelajarDataModel {
  'ASSESMENT': boolean
  'CERTIFICATE': boolean
  'CLASS_TYPE': string
  'CURRENCY': string
  'DESCRIPTION': string
  'DETAIL_AVAILABLE': boolean
  'DISCOUNTED_FEE': number
  'DURATION': string
  'FEE': number
  'ID': number
  'IMAGE_HEADER': string
  'IMAGE_THUMBNAIL': string
  'IS_ACTIVE': boolean
  'PROGRAM_CAPTION': string
  'PROGRAM_NAME': string
  'PROGRAM_SUBJECT_STYLE': string
  'PROGRAM_TAG': string
  'PROGRAM_TYPE': string
}

interface ProgramHeadlineDataModel {
  'CONTENT_DESCRIPTION': string
  'CONTENT_TITLE': string
}

interface SliderDataModel {
  'ACTIVE': boolean
  'DESCRIPTION': string
  'ID': number
  'IMAGE_DESKTOP': string
  'IMAGE_MOBILE': string
  'TITLE': string
}

interface TestimonyDataModel {
  BODY: string
  CREATED_AT: string
  CREATED_BY: string
  DELETED_AT: string
  DELETED_BY: string
  ID: number
  IMAGE: string
  IS_ACTIVE: boolean
  NAME: string
  UPDATED_AT: string
  UPDATED_BY: string
}

export interface LandingDataModel {
  about: AboutDataModel
  careerHeadline: ProgramHeadlineDataModel
  coCreation: {
    facility: CoCreationDataModel[]
    headline: CoCreationDataModel
    title: CoCreationDataModel
    values: CoCreationDataModel[]
  }
  gallery: GalleryDataModel[]
  headline: HeadlineDataModel[]
  programBelajar: {
    headline: ProgramHeadlineDataModel
    programs: ProgramBelajarDataModel[]
  }
  slider: SliderDataModel[]
  testimony: {
    headline: ProgramHeadlineDataModel
    testimonies: TestimonyDataModel[]
  }
}

export enum LandingActionType {
  GetLandingAbout = 'Landing➝GetLandingAbout',
  GetLandingCoCreationValues = 'Landing➝GetLandingCoCreationValues',
  GetLandingCoCreationFacility = 'Landing➝GetLandingCoCreationFacility',
  GetLandingCoCreationHeadline = 'Landing➝GetLandingCoCreationHeadling',
  GetLandingCoCreationTitle = 'Landing➝GetLandingCoCreationTitle',
  GetLandingGallery = 'Landing➝GetLandingGallery',
  GetLandingHeadline = 'Landing➝GetLandingHeadling',
  GetLandingProgramBelajar = 'Landing➝GetLandingProgramBelajar',
  GetLandingProgramBelajarHeadline = 'Landing➝GetLandingProgramBelajarheadline',
  GetLandingSliders = 'Landing➝GetLandingSliders',
  GetLandingTestimony = 'Landing➝GetLandingTestimony',
  GetLandingTestimonyHeadline = 'Landing➝GetLandingTestimonyHeadline',
  GetLandingCareerHeadline = 'Landing➝GetLandingCareerHeadline'
}
export type LandingAction = {
  data: AboutDataModel
  type: LandingActionType.GetLandingAbout
} | {
  data: CoCreationDataModel
  type: LandingActionType.GetLandingCoCreationHeadline
} | {
  data: CoCreationDataModel
  type: LandingActionType.GetLandingCoCreationTitle
} | {
  data: CoCreationDataModel[]
  type: LandingActionType.GetLandingCoCreationFacility
} | {
  data: CoCreationDataModel[]
  type: LandingActionType.GetLandingCoCreationValues
} | {
  data: GalleryDataModel[]
  type: LandingActionType.GetLandingGallery
} | {
  data: HeadlineDataModel[]
  type: LandingActionType.GetLandingHeadline
} | {
  data: ProgramBelajarDataModel[]
  type: LandingActionType.GetLandingProgramBelajar
} | {
  data: ProgramHeadlineDataModel
  type: LandingActionType.GetLandingCareerHeadline
} | {
  data: ProgramHeadlineDataModel
  type: LandingActionType.GetLandingProgramBelajarHeadline
} | {
  data: ProgramHeadlineDataModel
  type: LandingActionType.GetLandingTestimonyHeadline
} | {
  data: SliderDataModel[]
  type: LandingActionType.GetLandingSliders
} | {
  data: TestimonyDataModel[]
  type: LandingActionType.GetLandingTestimony
};

export type { AboutDataModel };
