import type {
  FC, PropsWithChildren
} from 'react';

export type BlankLayoutProps = PropsWithChildren<{}>;

export const BlankLayout: FC<BlankLayoutProps> = ({ children }) => {
  return (
    <div className="blank-page">
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-body">{children}</div>
        </div>
      </div>
    </div>
  );
};
