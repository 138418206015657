import { Provider, useDispatch, useSelector } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { default as createDebounce } from 'redux-debounced';
import { default as thunk } from 'redux-thunk';

import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

import { AppCommand, AppReducer } from './app/reducers';
import { ArticleCommand, ArticleDefault, ArticleReducer } from './article/reducers';
import { BannerCommand, BannerDefault, BannerReducer } from './banner/reducers';
import { EventCommand, EventDefault, EventReducer } from './event/reducers';
import { GeneralCommand, GeneralDefault, GeneralReducer } from './general/reducers';
import { InitCommand, InitDefault, InitReducer } from './init/reducers';
import { JobsCommand, JobsDefault, JobsReducer } from './jobs/reducers';
import { KarirCommand, KarirDefault, KarirReducer } from './karir/reducers';
import { KolaborasiCommand, KolaborasiDefault, KolaborasiReducer } from './kolaborasi/reducers';
import { LandingCommand, LandingDefault, LandingReducer } from './landing/reducers';
import { ProgramCommand, ProgramDefault, ProgramReducer } from './program/reducers';

import type { RootAction, RootModel, TDispatch } from './types';

// ** Init reducers
const reducers = combineReducers({
  app: AppReducer,
  init: InitReducer,
  general: GeneralReducer,
  article: ArticleReducer,
  program: ProgramReducer,
  event: EventReducer,
  banner: BannerReducer,
  jobs: JobsReducer,
  landing: LandingReducer,
  kolaborasi: KolaborasiReducer,
  karir: KarirReducer
});

// ** Init value
const rootValue: RootModel = {
  app: {},
  init: InitDefault,
  general: GeneralDefault,
  article: ArticleDefault,
  program: ProgramDefault,
  event: EventDefault,
  banner: BannerDefault,
  jobs: JobsDefault,
  landing: LandingDefault,
  kolaborasi: KolaborasiDefault,
  karir: KarirDefault
};

// ** Init middleware
const middlewares = [thunk, createDebounce()];

// ** Create store
const store = createStore(
  reducers,
  rootValue,
  composeWithDevToolsDevelopmentOnly(applyMiddleware(...middlewares))
);

const StoreCommand = {
  app: AppCommand,
  init: InitCommand,
  general: GeneralCommand,
  article: ArticleCommand,
  program: ProgramCommand,
  event: EventCommand,
  banner: BannerCommand,
  jobs: JobsCommand,
  landing: LandingCommand,
  kolaborasi: KolaborasiCommand,
  karir: KarirCommand
};

const StoreProvider = (props: React.PropsWithChildren) => <Provider store={store} {...props} />;

function useStore<TState = RootModel, TSelected = unknown>(
  selector: (state: TState) => TSelected
): [TSelected | undefined, TDispatch<RootAction>] {
  return [
    useSelector(selector),
    useDispatch<TDispatch<RootAction>>()
  ];
}

StoreProvider.displayName = 'StoreProvider';
export { StoreCommand, StoreProvider, useStore };
