// ** Vertical Menu Components
import { SidebarGroup } from './sidebar-group';
import { SidebarLink } from './sidebar-link';
import { SidebarSection } from './sidebar-section';
import { resolveSidebarItemComponent } from './utils';

export const SidebarItems = (props: unknown) => {
  // ** Components Object
  const Components = {
    SidebarSection,
    SidebarGroup,
    SidebarLink
  };

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item: unknown, index: number) => {
    const TagName = Components[resolveSidebarItemComponent(item)];

    return <TagName item={item} key={index} {...props} />;
  });

  return RenderNavItems;
};
