/* eslint-disable react/display-name */
/* eslint-disable react/no-unused-prop-types */
import type { FC } from 'react';

import { NavbarUser } from './navbar-user';

interface NavbarProps {
  openNavbar?: boolean
  toggleOpenNavbar?: () => void
}

export const Navbar: FC<NavbarProps> = ({
  openNavbar,
  toggleOpenNavbar
}) => {
  return (
    <NavbarUser openNavbar={openNavbar} toggleOpenNavbar={toggleOpenNavbar} />
  );
};
