import type { FC, SVGAttributes } from 'react';

import { classNames } from '@nxweb/core/strings';
import { SVG } from '@nxweb/core/widgets';

export interface IconProps extends SVGAttributes<SVGElement> {
  src: string

  color?: string
  size?: number | string
  title?: string
  svgClassName?: string
}

export const Icon: FC<IconProps> = ({
  className = '',
  src,
  svgClassName = 'mh-50px'
}) => {
  return (
    <span className={classNames('svg-icon', className)}>
      <SVG className={svgClassName} src={src} />
    </span>
  );
};

export const IconHamburger: FC<IconProps> = (
  {
    size = 16,
    ...rest
  }
) => {
  return (
    <svg {...rest} fill="none" height={size} viewBox="0 0 18 12" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill="white" />
    </svg>
  );
};
