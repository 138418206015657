import type { FC, ForwardedRef, PropsWithChildren, ReactNode } from 'react';
import { forwardRef } from 'react';
import { Badge } from 'react-bootstrap';

import { classNames } from '@nxweb/core/strings';

import DefaultAvatar from './avatar.svg';

// ** PropTypes
interface AvatarProps {
  icon?: ReactNode
  size?: 'lg' | 'sm' | 'xl'
  status?: 'away' | 'busy' | 'offline' | 'online'
  badgeColor?:
  'danger' | 'dark' | 'info' | 'light-danger' | 'light-dark' | 'light-info' | 'light-primary' | 'light-secondary' | 'light-success' | 'light-warning' | 'primary' | 'secondary' | 'success' | 'warning'
  color?:
  'danger' | 'dark' | 'info' | 'light-danger' | 'light-dark' | 'light-info' | 'light-primary' | 'light-secondary' | 'light-success' | 'light-warning' | 'primary' | 'secondary' | 'success' | 'warning'
  imgClassName?: string
  className?: string
  src?: string
  tag?: string | ((props: PropsWithChildren<{
    className: string
    ref: ForwardedRef<unknown>
  }>) => string)
  badgeUp?: boolean
  content?: ReactNode
  contentStyles?: object
  badgeText?: string
  img?: string | false
  imgHeight?: number | string
  imgWidth?: number | string
  initials?: boolean | string
}

export const Avatar: FC<AvatarProps> = forwardRef((props, ref) => {
  // ** Props
  const {
    color,
    className,
    imgClassName,
    initials,
    size,
    badgeUp,
    content,
    icon,
    badgeColor,
    badgeText,
    img,
    imgHeight,
    imgWidth,
    status,
    tag: Tag = 'div',
    contentStyles,
    ...rest
  } = props;

  // ** Function to extract initials from content
  const getInitials = (str: string): string => {
    const results: string[] = [];
    const wordArray = str.split(' ');

    wordArray.forEach((e) => {
      results.push(e[0]);
    });

    return results.join('');
  };

  return (
    <Tag
      className={classNames('avatar', className, {
        [`bg-${color}`]: color,
        [`avatar-${size}`]: size
      })}
      ref={ref}
      {...rest}
    >
      {icon || img === false || typeof img === 'undefined'
        ? (
        <span
          className={classNames('avatar-content', {
            'position-relative': badgeUp
          })}
          style={contentStyles}
        >
          {initials && typeof content === 'string' ? getInitials(content) : content}

          {icon || null}
          {badgeUp
            ? (
            <Badge className="badge-sm badge-up" color={badgeColor || 'primary'} pill={true}>
              {badgeText || '0'}
            </Badge>
            )
            : null}
        </span>
        )
        : (
        <img
          alt="avatarImg"
          className={imgClassName}
          height={imgHeight && !size ? imgHeight : 32}
          src={img || DefaultAvatar}
          width={imgWidth && !size ? imgWidth : 32} />
        )}
      {status
        ? (
        <span
          className={classNames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size
          })} />
        )
        : null}
    </Tag>
  );
});

(Avatar as unknown).DefaultPicture = DefaultAvatar;
