import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from '@nxweb/auth';
import { AppRouter } from '@nxweb/core/router';
import { ServiceWorker, WebVitals } from '@nxweb/core/web';

import { Spinner } from '@components/spinner';
import { Auth } from '@config/auth';
import { RouteConfig } from '@config/routes';
import { LayoutProvider, Layouts } from '@layouts/index';
import { StoreProvider } from '@models/store';

import '@styles/index.scss';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

root.render(<>
  <StoreProvider>
    <AuthProvider
      config={Auth}
      exclude={['/', '/home', '/course', '/jobs', '/article']}
      loader={<Spinner />}
      manual={true}
    >
    <AppRouter
      defaultLayout="default"
      fallback={<Spinner />}
      layouts={Layouts}
      routes={RouteConfig}
      wrapper={LayoutProvider} />
    </AuthProvider>
  </StoreProvider>
  <ToastContainer closeOnClick={true} draggable={false} pauseOnFocusLoss={false} />
            </>);

/**
 * ServiceWorker registration
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below, and set the pwa property to true in .nextweb.js.
 *
 * Note:
 * This comes with some pitfalls though, make sure you know the implications.
 */

ServiceWorker.unregister();

/**
 * Performance metric reporting
 * If you want your app to send anlytics metrics you can pass a custom
 * handler for processing the metrics below.
 * The `console.debug` is provided as example only.
 *
 * Note:
 * You can remove/ uncomment if you don't need metrics reporting
 */

WebVitals.register(console.debug);

/**
 * The default application export
 * If enabled, this will be available as global `NX` object
 *
 * Note:
 * At runtime these could be overwritten by the host application.
 */

export default {
  env: {
    /*
     * Define X_API_URL in .env to populate this value
     * ApiURL: process.env.API_URL
     */
    ApiBase: process.env.API_BASE,
    ApiBasePath: process.env.API_BASE_PATH,
    S3Url: process.env.S3_URL
  }
};
