import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import type { RouteType } from '@nxweb/core/router';

export const RouteConfig: RouteType[] = [
  {
    path: '/',
    title: 'Home',
    component: () => Navigate({ to: '/home' })
  },
  {
    path: '/home',
    title: 'Home',
    component: lazy(() => import('@views/landing/index.js').then((m) => ({ default: m.Landing })))
  },
  {
    path: '/artikel',
    title: 'Artikel',
    component: lazy(() => import('@views/articles/index.js').then((m) => ({ default: m.Article })))
  },
  {
    path: '/artikel/articledetail',
    title: 'Detail Artikel',
    component: lazy(() => import('@views/articles/articledetail.js').then((m) => ({ default: m.ArticleDetail })))
  },
  {
    path: '/event',
    title: 'Event',
    component: lazy(() => import('@views/event/index.js').then((m) => ({ default: m.Event })))
  },
  {
    path: '/event/:id',
    title: 'Detail Event',
    component: lazy(() => import('@views/event/detail.js').then((m) => ({ default: m.EventDetail })))
  },
  {
    path: '/program',
    title: 'Program',
    component: lazy(() => import('@views/program/index.js').then((m) => ({ default: m.Program })))
  },
  {
    path: '/program/daftar/:id',
    title: 'Program - Daftar',
    component: lazy(() => import('@views/program/daftar.js').then((m) => ({ default: m.ProgramDaftar })))
  },
  {
    path: '/program/detail/:id',
    title: 'Program - Detail',
    component: lazy(() => import('@views/program/detail.js').then((m) => ({ default: m.ProgramDetail })))
  },
  {
    path: '/karir',
    title: 'Karir',
    component: lazy(() => import('@views/karir/index.js').then((m) => ({ default: m.Karir })))
  },
  {
    path: '/karir/:id',
    title: 'Karir Detail',
    component: lazy(() => import('@views/karir/detail.js').then((m) => ({ default: m.DetailKarir })))
  },
  {
    path: '/kolaborasi',
    title: 'Kolaborasi',
    component: lazy(() => import('@views/kolaborasi/index.js').then((m) => ({ default: m.Kolaborasi })))
  },
  {
    path: '/typos',
    title: 'Typos',
    component: lazy(() => import('@views/typos.js').then((m) => ({ default: m.Typos })))
  }
];
