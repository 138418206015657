import { apiFetch } from '@api/base';

import { ArticleActionType } from './types';

import type { ArticleAction, ArticleModel } from './types';
import type { TAction, TDispatch } from '../types';

export const ArticleDefault: ArticleModel = {
  ArticleListAll: [],
  ArticleList: {
    output: [],
    total: 0
  },
  ArticleDetail: []
};

export const ArticleReducer = (
  state: ArticleModel = ArticleDefault,
  action: ArticleAction
) => {
  switch (action.type) {
    case ArticleActionType.ArticleListAll:
      return { ...state, ArticleListAll: action.data };
    case ArticleActionType.ArticleList:
      return { ...state, ArticleList: action.data };
    case ArticleActionType.ArticleDetail:
      return { ...state, ArticleDetail: action.data };

    default:
      return { ...state };
  }
};

export const ArticleCommand = {
  getArticleListAll: (): TAction<ArticleAction, void> => {
    return (dispatch: TDispatch<ArticleAction>) => {
      return apiFetch()
        .post('/external/article/get-dynamic', {
          filter: {
            filters: [
              {
                field: 'published',
                operator: 'eq',
                value: 'true'
              }
            ],
            logic: 'AND'
          },
          page: 1,
          pageSize: 6,
          skip: 0,
          sort: [
            {
              dir: 'DESC',
              field: 'published_at'
            }
          ]
        })
        .then((response) => {
          dispatch({
            type: ArticleActionType.ArticleListAll,
            data: response.data.output
          });
        });
    };
  },

  getArticleList: (input: number): TAction<ArticleAction, void> => {
    return (dispatch: TDispatch<ArticleAction>) => {
      return apiFetch()
        .post('/external/article/get-dynamic', {
          filter: {
            filters: [
              {
                field: 'published',
                operator: 'eq',
                value: 'true'
              }
            ],
            logic: 'AND'
          },
          page: input + 1,
          pageSize: 8,
          skip: input * 8,
          sort: [
            {
              dir: 'DESC',
              field: 'published_at'
            }
          ]
        })
        .then((response) => {
          dispatch({
            type: ArticleActionType.ArticleList,
            data: response.data
          });
        });
    };
  },

  getArticleDetail: (id: string): TAction<ArticleAction, void> => {
    return (dispatch: TDispatch<ArticleAction>) => {
      return apiFetch()
        .get(`/external/article/by-id?_id=${id}`)
        .then((response) => {
          dispatch({
            type: ArticleActionType.ArticleDetail,
            data: response.data.output
          });
        });
    };
  }
};
