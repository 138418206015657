// Import axios from 'axios';
import { apiFetch } from '@api/base';

import { BannerActionType } from './types';

import type { BannerAction, BannerModel } from './types';
import type { TAction, TDispatch } from '../types';

export const BannerDefault: BannerModel = {
  bannerList: []
};

export const BannerReducer = (state: BannerModel = BannerDefault, action: BannerAction) => {
  switch (action.type) {
    case BannerActionType.GetBanner:

      return { ...state, bannerList: action.data };

    default:
      return { ...state };
  }
};

export const BannerCommand = {

  getBannerList: (): TAction<BannerAction, void> => {
    return (dispatch: TDispatch<BannerAction>) => {
      return apiFetch().get('/external/banner/slider')
        .then((response) => {
          dispatch({
            type: BannerActionType.GetBanner,
            data: response.data.output
          });
        });
    };
  }

};
