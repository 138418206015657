import type { FetchInstance, FetchMockOptions } from '@nxweb/core/fetch';
import { createFetch, createFetchURL } from '@nxweb/core/fetch';

import type { PagingInfo } from '@models/types';

export const getApiURL = () => window.NX?.env?.ApiURL; // This will be undefined

export const apiURL = (endpoint: string, options: PagingInfo = {}) => createFetchURL(endpoint, {
  baseURL: getApiURL(), ...options
});

export const mockAPI = (instance: FetchInstance, options?: FetchMockOptions): FetchInstance => {
  instance.mock({ delayResponse: 1000, ...options || {} }).onAny().passThrough();

  return instance;
};

export const API = createFetch({
  baseURL: getApiURL()
});

// Token: string
export const apiFetch = () => createFetch({
  baseURL: `${window.NX?.env?.ApiBase}${window.NX?.env?.ApiBasePath}`,

  /*
   * Headers: {
   *   Authorization: token
   * },
   */
  debug: process.env.NODE_ENV !== 'production'
});

/*
 * Export const testFetch = () => createFetch({
 *   baseURL: `${window.NX?.env?.ApiBase}`,
 *   debug: process.env.NODE_ENV !== 'production'
 * });
 */
