// Import axios from 'axios';

import { apiFetch } from '@api/base';

import { GeneralActionType } from './types';

import type { GeneralAction, GeneralModel } from './types';
import type { TAction, TDispatch } from '../types';

export const GeneralDefault: GeneralModel = {
  footer: {
    ABOUT_US: '',
    ADDRESS: '',
    ADDRESS_URL: '',
    CONTACT_US: '',
    EMAIL: '',
    FACEBOOK: '',
    INSTAGRAM: '',
    LINKEDIN: '',
    PHONE_NUMBER: '',
    TWITTER: '',
    WHATSAPP: '',
    YOUTUBE: ''
  }
};

export const GeneralReducer = (state: GeneralModel = GeneralDefault, action: GeneralAction) => {
  switch (action.type) {
    case GeneralActionType.GetFooter:

      return { ...state, footer: action.data };

    default:
      return { ...state };
  }
};

export const GeneralCommand = {

  getFooter: (): TAction<GeneralAction, void> => {
    return (dispatch: TDispatch<GeneralAction>) => {
      return apiFetch().get('/external/about-us/get')
        .then((response) => {
          dispatch({
            type: GeneralActionType.GetFooter,
            data: response.data.output[0]
          });
        });
    };
  }

};
