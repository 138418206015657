interface Article {
  AUTHOR: string
  CONTENT: string
  ID: number
  IMAGE_HEADER: string
  IMAGE_THUMBNAIL: string
  PUBLISHED: boolean
  PUBLISHED_AT: string
  TITLE: string
}

/*
 * Interface ArticleDetail {
 *   Description?: string;
 * }
 */

export interface ArticleModel {
  ArticleListAll: Article[]
  ArticleList: {
    output: Article[]
    total: number
  }
  ArticleDetail: Article[]
}

export enum ArticleActionType {
  ArticleListAll = 'Article➝ GetArticleListAll',
  ArticleList = 'Article➝ GetArticleList',
  ArticleDetail = 'Article➝ GetArticleDetail'
}

export type ArticleAction =
  {
    type: ArticleActionType.ArticleDetail
    data: Article[]
  } | {
    type: ArticleActionType.ArticleList
    data: Article[]
  } | {
    type: ArticleActionType.ArticleListAll
    data: Article[]
  };
