/* eslint-disable capitalized-comments */
/* eslint-disable lines-around-comment */
/* eslint-disable multiline-comment-style */
// Import axios from 'axios';
import { apiFetch } from '@api/base';

import { LandingActionType } from './types';

import type { LandingAction, LandingDataModel } from './types';
import type { TAction, TDispatch } from '../types';

const DEFAULT_ABOUT_DATA = {
  ABOUT_US: '',
  ADDRESS: '',
  ADDRESS_URL: '',
  CONTACT_US: '',
  EMAIL: '',
  FACEBOOK: '',
  INSTAGRAM: '',
  LINKEDIN: '',
  PHONE_NUMBER: '',
  TWITTER: '',
  WHATSAPP: '',
  YOUTUBE: ''
};

const DEFAULT_CO_CREATION_DATA = {
  CREATED_AT: '',
  DELETED_AT: '',
  DESCRIPTION: '',
  ID: 0,
  IMAGE: '',
  NAME: '',
  TYPE: '',
  UPDATED_AT: ''
};

const DEFAULT_PROGRAM_HEADLINE_DATA = {
  CONTENT_DESCRIPTION: '',
  CONTENT_TITLE: ''
};

export const LandingDefault: LandingDataModel = {
  about: DEFAULT_ABOUT_DATA,
  careerHeadline: DEFAULT_PROGRAM_HEADLINE_DATA,
  coCreation: {
    facility: [],
    headline: DEFAULT_CO_CREATION_DATA,
    title: DEFAULT_CO_CREATION_DATA,
    values: []
  },
  gallery: [],
  headline: [],
  programBelajar: {
    headline: DEFAULT_PROGRAM_HEADLINE_DATA,
    programs: []
  },
  slider: [],
  testimony: {
    headline: DEFAULT_PROGRAM_HEADLINE_DATA,
    testimonies: []
  }
};

export const LandingReducer = (state: LandingDataModel = LandingDefault, action: LandingAction) => {
  switch (action.type) {
    case LandingActionType.GetLandingAbout:
      return { ...state, about: action.data };
    case LandingActionType.GetLandingCoCreationFacility:
      return { ...state, coCreation: { ...state.coCreation, facility: action.data } };
    case LandingActionType.GetLandingCoCreationHeadline:
      return { ...state, coCreation: { ...state.coCreation, headline: action.data } };
    case LandingActionType.GetLandingCoCreationValues:
      return { ...state, coCreation: { ...state.coCreation, values: action.data } };
    case LandingActionType.GetLandingCoCreationTitle:
      return { ...state, coCreation: { ...state.coCreation, title: action.data } };
    case LandingActionType.GetLandingGallery:
      return { ...state, gallery: action.data };
    case LandingActionType.GetLandingHeadline:
      return { ...state, headline: action.data };
    case LandingActionType.GetLandingProgramBelajar:
      return { ...state, programBelajar: { ...state.programBelajar, programs: action.data } };
    case LandingActionType.GetLandingProgramBelajarHeadline:
      return { ...state, programBelajar: { ...state.programBelajar, headline: action.data } };
    case LandingActionType.GetLandingSliders:
      return { ...state, slider: action.data };
    case LandingActionType.GetLandingTestimony:
      return { ...state, testimony: { ...state.testimony, testimonies: action.data } };
    case LandingActionType.GetLandingTestimonyHeadline:
      return { ...state, testimony: { ...state.testimony, headline: action.data } };
    case LandingActionType.GetLandingCareerHeadline:
      return { ...state, careerHeadline: action.data };

    default:
      return { ...state };
  }
};

export const LandingCommand = {
  getLandingAbout: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return apiFetch().get('/external/about-us/get')
        .then((response) => {
          dispatch({
            data: response.data.output[0],
            type: LandingActionType.GetLandingAbout
          });
        });
    };
  },
  getLandingCareerHeadline: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return apiFetch().get('/external/headline/career')
        .then((response) => {
          dispatch({
            data: response.data.output[0],
            type: LandingActionType.GetLandingCareerHeadline
          });
        });
    };
  },
  getLandingCoCreation: (type: 'Facility' | 'Landing-Page-Headline' | 'Landing-Page-Title' | 'Values'): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return apiFetch().post('/internal/co-creation-detail/get-by-type', { _type: type })
        .then((response) => {
          switch (type) {
            case 'Facility':
              dispatch({
                data: response.data.output,
                type: LandingActionType.GetLandingCoCreationFacility
              });
              break;
            case 'Values':
              dispatch({
                data: response.data.output,
                type: LandingActionType.GetLandingCoCreationValues
              });
              break;
            case 'Landing-Page-Title':
              dispatch({
                data: response.data.output[0],
                type: LandingActionType.GetLandingCoCreationTitle
              });
              break;
            case 'Landing-Page-Headline':
              dispatch({
                data: response.data.output[0],
                type: LandingActionType.GetLandingCoCreationHeadline
              });
              break;

            default:
              break;
          }
        });
    };
  },
  getLandingGallery: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return apiFetch().get('/external/gallery/get-limited')
        .then((response) => {
          dispatch({
            data: response.data.output,
            type: LandingActionType.GetLandingGallery
          });
        });
    };
  },
  getLandingHeadline: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return apiFetch().get('/external/headline/all')
        .then((response) => {
          dispatch({
            data: response.data.output,
            type: LandingActionType.GetLandingHeadline
          });
        });
    };
  },
  getLandingProgramBelajar: (tag: string): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return apiFetch().get('/external/program-main/get-by-tag', { params: { _tag: tag } })
        .then((response) => {
          dispatch({
            data: response.data.output,
            type: LandingActionType.GetLandingProgramBelajar
          });
        });
    };
  },
  getLandingProgramBelajarHeadline: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return apiFetch().get('/external/headline/program')
        .then((response) => {
          dispatch({
            data: response.data.output[0],
            type: LandingActionType.GetLandingProgramBelajarHeadline
          });
        });
    };
  },
  // getLandingSliders: (): TAction<LandingAction, void> => {
  //   return (dispatch: TDispatch<LandingAction>) => {
  //     return apiFetch().get('/external/banner/slider')
  //       .then((response) => {
  //         dispatch({
  //           data: response.data.output,
  //           type: LandingActionType.GetLandingSliders
  //         });
  //       });
  //   };
  // },
  getLandingSliders: (payload: unknown): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return apiFetch().post('/internal/banner/grid', payload)
        .then((response) => {
          dispatch({
            data: response.data.output,
            type: LandingActionType.GetLandingSliders
          });
        });
    };
  },
  getLandingTestimony: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return apiFetch().get('/external/testimonial?_is_active=true')
        .then((response) => {
          dispatch({
            data: response.data.output,
            type: LandingActionType.GetLandingTestimony
          });
        });
    };
  },
  getLandingTestimonyHeadline: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return apiFetch().get('/external/headline/testimonial')
        .then((response) => {
          dispatch({
            data: response.data.output[0],
            type: LandingActionType.GetLandingTestimonyHeadline
          });
        });
    };
  }
};
