// Import axios from 'axios';
import { apiFetch } from '@api/base';
import { notify } from '@lib/notify';

import { EventActionType } from './types';

import type { EventAction, EventModel, EventProps, EventRegistrationModel } from './types';
import type { TAction, TDispatch } from '../types';

const DEFAULT_EVENT_DETAIL: EventProps = {
  CREATED_AT: '',
  CREATED_BY: '',
  DELETED_AT: '',
  DELETED_BY: '',
  DESCRIPTION: '',
  DUE_DATE: '',
  EVENT_NAME: '',
  EVENT_POSTER: '',
  EVENT_VENUE: '',
  ID: 0,
  IS_FREE: false,
  TIME: '',
  UPDATED_AT: '',
  UPDATED_BY: ''
};

export const EventDefault: EventModel = {
  eventDetail: DEFAULT_EVENT_DETAIL,
  eventExpired: [],
  eventList: []
};

export const EventReducer = (state: EventModel = EventDefault, action: EventAction) => {
  switch (action.type) {
    case EventActionType.GetEventList:
      return { ...state, eventList: action.data };
    case EventActionType.GetEventExpired:
      return { ...state, eventExpired: action.data };
    case EventActionType.GetEventById:
      return { ...state, eventDetail: action.data };

    default:
      return { ...state };
  }
};

export const EventCommand = {
  getEventExpired: (): TAction<EventAction, void> => {
    return (dispatch: TDispatch<EventAction>) => {
      return apiFetch()
        .get(`/external/event-main/expired`)
        .then((response) => {
          dispatch({
            data: response.data.output,
            type: EventActionType.GetEventExpired
          });
        });
    };
  },
  getEventList: (): TAction<EventAction, void> => {
    return (dispatch: TDispatch<EventAction>) => {
      return apiFetch()
        .get(`/external/event/all`)
        .then((response) => {
          dispatch({
            data: response.data.output,
            type: EventActionType.GetEventList
          });
        });
    };
  },

  getEventById: (id: string): TAction<EventAction, void> => {
    return (dispatch: TDispatch<EventAction>) => {
      return apiFetch()
        .get(`/external/event/by-id?_id=${id}`)
        .then((response) => {
          dispatch({
            data: response.data.output[0],
            type: EventActionType.GetEventById
          });
        });
    };
  },
  postEventRegistration: (payload: EventRegistrationModel): Promise<string | undefined> => {
    return apiFetch()
      .post(`/external/event-participant/registration`, payload)
      .then((response) => {
        if (response.status === 200) {
          notify.success('Registration success');

          return 'ok';
        }
      });
  }
};
