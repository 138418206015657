interface FooterProps {
  ABOUT_US: string
  CONTACT_US: string
  ADDRESS: string
  ADDRESS_URL: string
  EMAIL: string
  FACEBOOK: string
  INSTAGRAM: string
  LINKEDIN: string
  PHONE_NUMBER: string
  TWITTER: string
  WHATSAPP: string
  YOUTUBE: string
}

export interface GeneralModel {
  footer: FooterProps
}

export enum GeneralActionType {
  GetFooter = 'General➝ GetFooter'
}
export interface GeneralAction {
  type: GeneralActionType.GetFooter
  data: FooterProps
}
