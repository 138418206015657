import { HouseFill } from '@nxweb/icons/bootstrap';

import { Avatar } from '@components/avatar/index';
import type { NavigationConfig as NavigationConfigType } from '@layouts/types';

export const NavigationConfig: NavigationConfigType = [
  {
    header: 'Menu'
  },
  {
    id: 'landingpage',
    text: 'LandingPage',
    icon: <Avatar className="me-1" icon={<HouseFill size={15} />} />,
    navLink: '/landingpage'
  },
  {
    id: 'articles',
    text: 'Articles',
    icon: <Avatar className="me-1" icon={<HouseFill size={15} />} />,
    navLink: '/articles'
  }
];
