import { apiFetch } from '@api/base';
import { notify } from '@lib/notify';
import type { Banner } from '@models/banner/types';

import { ProgramActionType } from './types';

import type { ProgramAction, ProgramModel } from './types';
import type { TAction, TDispatch } from '../types';

const PAGE_LIMIT = 6;

export const ProgramDefault: ProgramModel = {
  all: {
    output: [],
    total: 0
  },
  banners: [],
  bootcampList: [],
  programDetail: {
    ASSESMENT: false,
    BENEFIT: [],
    CERTIFICATE: false,
    CLASS_TYPE: '',
    COMPATIBLE: [],
    CURRENCY: '',
    DESCRIPTION: '',
    DETAIL_AVAILABLE: false,
    DISCOUNTED_FEE: 0,
    DURATION: '',
    FEE: 0,
    ID: 0,
    IMAGE_HEADER: '',
    IMAGE_THUMBNAIL: '',
    IS_ACTIVE: false,
    MENTOR: [],
    PROGRAM_CAPTION: '',
    PROGRAM_NAME: '',
    PROGRAM_SUBJECT_STYLE: '',
    PROGRAM_TAG: '',
    PROGRAM_TYPE: '',
    REQUIREMENT: [],
    SILABUS: [],
    SUBJECT: []
  },
  programList: [],
  title: {
    CONTENT_DESCRIPTION: '',
    CONTENT_MEDIA: '',
    CONTENT_TITLE: ''
  }
};

export const ProgramReducer = (
  state: ProgramModel = ProgramDefault,
  action: ProgramAction
) => {
  switch (action.type) {
    case ProgramActionType.GetProgramList:
      return { ...state, all: action.data };
    case ProgramActionType.GetProgramById:
      return { ...state, programDetail: action.data };
    case ProgramActionType.GetBootcampList:
      return { ...state, bootcampList: action.data };
    case ProgramActionType.GetProgramBanner:
      return { ...state, banners: action.data };
    case ProgramActionType.GetProgramTitle:
      return { ...state, title: action.data };

    default:
      return { ...state };
  }
};

export const ProgramCommand = {
  getBootcampList: (): TAction<ProgramAction, void> => {
    return (dispatch: TDispatch<ProgramAction>) => {
      return apiFetch().post(`/internal/program/grid`, {
        filter: {
          filters: [
            {
              field: 'PROGRAM_TYPE',
              operator: 'eq',
              value: 'Bootcamp'
            }
          ],
          logic: 'AND'
        },

        // PageSize: 6,
        skip: 0,
        sort: []
      }).then((response) => {
        dispatch({
          data: response.data.output,
          type: ProgramActionType.GetBootcampList
        });
      });
    };
  },
  getProgramBanner: (): TAction<ProgramAction, void> => {
    return (dispatch: TDispatch<ProgramAction>) => {
      return apiFetch()
        .get(`/external/banner?_type=Promo-Program`)
        .then((response) => {
          const banners = response.data.output.map((o: Banner) => o.IMAGE_DESKTOP);

          dispatch({
            data: banners,
            type: ProgramActionType.GetProgramBanner
          });
        });
    };
  },
  getProgramById: (id: string): TAction<ProgramAction, void> => {
    return (dispatch: TDispatch<ProgramAction>) => {
      return apiFetch()
        .get(`/external/program/data?_id=${id}`)
        .then((response) => {
          dispatch({
            data: response.data,
            type: ProgramActionType.GetProgramById
          });
        });
    };
  },
  getProgramList: (): TAction<ProgramAction, void> => {
    return (dispatch: TDispatch<ProgramAction>) => {
      return apiFetch()
        .get('/external/program-main/get')
        .then((response) => {
          dispatch({
            data: response.data.output,
            type: ProgramActionType.GetProgramList
          });
        });
    };
  },
  getProgramListGrid: (page: number, filter: { search: string, type: string }): TAction<ProgramAction, void> => {
    return (dispatch: TDispatch<ProgramAction>) => {
      const filterList = [];
      if (filter.search) {
        filterList.push({
          field: 'PROGRAM_NAME',
          operator: 'contains',
          value: filter.search
        });
      }

      if (filter.type) {
        filterList.push({
          field: 'PROGRAM_TYPE',
          operator: 'eq',
          value: filter.type
        });
      }

      return apiFetch().post(`/internal/program/grid`, {
        filter: {
          filters: filterList,
          logic: 'AND'
        },
        pageSize: PAGE_LIMIT,
        skip: page * PAGE_LIMIT,
        sort: []
      }).then((response) => {
        dispatch({
          data: response.data,
          type: ProgramActionType.GetProgramList
        });
      });
    };
  },
  getProgramTitle: (): TAction<ProgramAction, void> => {
    return (dispatch: TDispatch<ProgramAction>) => {
      return apiFetch()
        .get('/external/headline/program')
        .then((response) => {
          const titleFound = response.data.output.find((o) => o.CONTENT_TYPE === 'Program Title');
          if (titleFound) {
            const title = {
              CONTENT_DESCRIPTION: titleFound.CONTENT_DESCRIPTION,
              CONTENT_MEDIA: titleFound.CONTENT_MEDIA,
              CONTENT_TITLE: titleFound.CONTENT_TITLE
            };

            dispatch({
              data: title,
              type: ProgramActionType.GetProgramTitle
            });
          }
        });
    };
  },
  registerUserToProgram: async (payload: unknown): Promise<string | undefined> => {
    const response = await apiFetch().post('/external/program/register', payload);

    if (response.status === 200) {
      notify.success('Registration successful');

      return 'ok';
    }
  }
};
