// Import axios from 'axios';
import { apiFetch } from '@api/base';

import { KolaborasiActionType } from './types';

import type { KolaborasiAction, KolaborasiModel } from './types';
import type { TAction, TDispatch } from '../types';

const DEFAULT_KOLABORASI_DATA = {
  CREATED_AT: '',
  DELETED_AT: '',
  DESCRIPTION: '',
  ID: 0,
  IMAGE: '',
  NAME: '',
  TYPE: '',
  UPDATED_AT: ''
};

export const KolaborasiDefault: KolaborasiModel = {
  business: {
    headline: DEFAULT_KOLABORASI_DATA,
    title: DEFAULT_KOLABORASI_DATA
  },
  community: {
    headline: DEFAULT_KOLABORASI_DATA,
    landingPage: DEFAULT_KOLABORASI_DATA,
    title: DEFAULT_KOLABORASI_DATA
  }
};

export const KolaborasiReducer = (state: KolaborasiModel = KolaborasiDefault, action: KolaborasiAction) => {
  switch (action.type) {
    case KolaborasiActionType.GetKolaborasiCommunityHeadline:
      return { ...state, community: { ...state.community, headline: action.data } };
    case KolaborasiActionType.GetKolaborasiCommunityTitle:
      return { ...state, community: { ...state.community, title: action.data } };
    case KolaborasiActionType.GetKolaborasiBusinessHeadline:
      return { ...state, business: { ...state.business, headline: action.data } };
    case KolaborasiActionType.GetKolaborasiBusinessTitle:
      return { ...state, business: { ...state.business, title: action.data } };
    case KolaborasiActionType.GetKolaborasiLandingPageHeadline:
      return { ...state, community: { ...state.community, landingPage: action.data } };

    default:
      return { ...state };
  }
};

export const KolaborasiCommand = {

  getKolaborasiByType: (type: 'Business-Headline' | 'Business-Title' | 'Community-Headline' | 'Community-Title' | 'Landing-Page-Headline'): TAction<KolaborasiAction, void> => {
    return (dispatch: TDispatch<KolaborasiAction>) => {
      return apiFetch().post('/internal/co-creation-detail/get-by-type', { _type: type })
        .then((response) => {
          switch (type) {
            case 'Business-Headline':
              dispatch({
                data: response.data.output[0],
                type: KolaborasiActionType.GetKolaborasiBusinessHeadline
              });
              break;
            case 'Business-Title':
              dispatch({
                data: response.data.output[0],
                type: KolaborasiActionType.GetKolaborasiBusinessTitle
              });
              break;
            case 'Community-Headline':
              dispatch({
                data: response.data.output[0],
                type: KolaborasiActionType.GetKolaborasiCommunityHeadline
              });
              break;
            case 'Community-Title':
              dispatch({
                data: response.data.output[0],
                type: KolaborasiActionType.GetKolaborasiCommunityTitle
              });
              break;
            case 'Landing-Page-Headline':
              dispatch({
                data: response.data.output[0],
                type: KolaborasiActionType.GetKolaborasiLandingPageHeadline
              });
              break;

            default:
              break;
          }
        });
    };
  }

};
