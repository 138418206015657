/* eslint-disable import/no-unresolved */
import { useEffect } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';

import { Whatsapp } from '@nxweb/icons/bootstrap';
import { Mail, Phone } from '@nxweb/icons/feather';

import type { AboutDataModel } from '@models/landing/types';
import { StoreCommand, useStore } from '@models/store';

import facebookIcon from '@assets/icons/ic-facebook.svg';
import instagramIcon from '@assets/icons/ic-instagram.svg';
import twitterIcon from '@assets/icons/ic-twitter.svg';
import youtubeIcon from '@assets/icons/ic-youtube.svg';
import mainLogo from '@assets/logo/logo-black 1.svg';

const footerLinks = (data: AboutDataModel) => [
  {
    title: 'PT. Makers Institute',
    description: <>
      <div className="text-body-1-regular mb-25">
        {data.ADDRESS}
      </div>
      <div className="text-body-1-regular mb-25 d-flex align-items-center gap-1">
        <Whatsapp size={16} />
        {data.WHATSAPP}
      </div>
      <div className="text-body-1-regular mb-25 d-flex align-items-center gap-1">
        <Phone size={16} />
        {data.PHONE_NUMBER}
      </div>
      <div className="text-body-1-regular mb-25 d-flex align-items-center gap-1">
        <Mail size={16} />
        {data.EMAIL}
      </div>
                 </>
  },
  {
    title: 'Co-creation Hub',
    links: [
      {
        text: 'Booking',
        to: '/kolaborasi'
      },
      {
        text: 'Trial',
        to: '/kolaborasi'
      },
      {
        text: 'Facilities',
        to: '/kolaborasi'
      }
    ]
  },
  {
    title: 'Course',
    links: [
      {
        text: 'Web Development',
        to: '/program'
      },
      {
        text: 'UI/UX Design',
        to: '/program'
      },
      {
        text: 'Mobile Development',
        to: '/program'
      },
      {
        text: 'Machine Learning',
        to: '/program'
      }
    ]
  },
  {
    title: 'Bootcamp',
    links: [
      {
        text: 'Web Development',
        to: '/program'
      },
      {
        text: 'UI/UX Design',
        to: '/program'
      },
      {
        text: 'Mobile Development',
        to: '/program'
      },
      {
        text: 'Machine Learning',
        to: '/program'
      }
    ]
  },
  {
    title: 'Resource',
    links: [
      {
        text: 'About Us',
        to: '#'
      },
      {
        text: 'Career',
        to: '/karir'
      }
    ]
  }
];

const Footer = () => {
  const [store, dispatch] = useStore((state) => state.landing?.about);

  useEffect(() => {
    dispatch(
      StoreCommand.landing.getLandingAbout()
    );
  }, [dispatch]);

  return (
    <footer className="footer" id="footer">
      <Row className="footer-top">
        <Col className="d-flex align-items-center" lg="auto" xs={6}>
          <div>
            <img alt="" className="img-fluid" src={mainLogo} />
          </div>
        </Col>
        <Col className="footer-socmed d-flex justify-content-end" lg="auto" xs={6}>
          <Nav.Link className="socmed-icon" href={store?.TWITTER} target="_blank">
            <img alt="twitter" className="img-fluid" src={twitterIcon} />
          </Nav.Link>
          <Nav.Link className="socmed-icon" href={store?.YOUTUBE} target="_blank">
            <img alt="youtube" className="img-fluid" src={youtubeIcon} />
          </Nav.Link>
          <Nav.Link className="socmed-icon" href={store?.INSTAGRAM} target="_blank">
            <img alt="instagram" className="img-fluid" src={instagramIcon} />
          </Nav.Link>
          <Nav.Link className="socmed-icon" href={store?.FACEBOOK} target="_blank">
            <img alt="facebook" className="img-fluid" src={facebookIcon} />
          </Nav.Link>
        </Col>
      </Row>
      <Row className="footer-links">
        {store
          ? footerLinks(store)?.map((o, idx) => {
            return (
              <Col className="link-section" key={idx} lg="auto" xs={12}>
                <div className="footer-title text-neutral-90 mb-1">
                  {o.title}
                </div>
                {o.description
                  ? o.description
                  : null}
                {o.links && o.links.length > 0
                  ? <Nav>
                    {o.links.map((x, y) => {
                      return (
                        <Nav.Item key={y}>
                          <Nav.Link className="text-body-1-regular" href={x.to}>
                            {x.text}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                    </Nav>
                  : null}
              </Col>
            );
          })
          : null}
      <hr className="mt-3"/>
      </Row>
      <div className="footer-legal">
        © ️PT. Makers Institute {new Date().getFullYear()}. All rights reserved.
      </div>
    </footer>
  );
};

Footer.displayName = 'Footer';
export default Footer;
