export enum InitActionType {
  GetAllCategories = '⌘➝Init➝GetAllCategories',
  GetAllApplications = '⌘➝Init➝GetAllApplications'
}

interface InitModelData {
  data: unknown[]
  total: number
}

export interface InitModel {
  allCategories: InitModelData
  allApplications: InitModelData
}

export type InitAction = {
  type: InitActionType.GetAllApplications
  data: InitModelData
} | {
  type: InitActionType.GetAllCategories
  data: InitModelData
};
