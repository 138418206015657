import type { TDispatch } from '@models/types';

import { AppActionType } from './types';

import type { AppAction, AppModel } from './types';

export const AppReducer = (state: AppModel = [], action: AppAction): AppModel => {
  switch (action.type) {
    case AppActionType.Load:
      return state;

    default:
      return state;
  }
};

export const AppCommand = {
  load() {
    return async (dispatch: TDispatch<AppAction>) => {
      const data: AppModel = {};

      dispatch({
        type: AppActionType.Load,
        data
      });
    };
  }
};
