interface Program {
  ASSESMENT: boolean
  CERTIFICATE: boolean
  CLASS_TYPE: string
  CURRENCY: string
  DESCRIPTION: string
  DETAIL_AVAILABLE: boolean
  DISCOUNTED_FEE: number
  DURATION: string
  FEE: number
  ID: number
  IMAGE_HEADER: string
  IMAGE_THUMBNAIL: string
  IS_ACTIVE: boolean
  PROGRAM_CAPTION: string
  PROGRAM_NAME: string
  PROGRAM_SUBJECT_STYLE: string
  PROGRAM_TAG: string
  PROGRAM_TYPE: string
}

interface SilabusDetailModel {
  DESCRIPTION: string
  ID: number
  LESSON: string
  SILABUS_ID: number
}

interface SilabusModel {
  ID: number
  PROGRAM_ID: number
  SILABUS_DETAIL: SilabusDetailModel[]
  SILABUS_TITLE: string
}

interface SubjectModel {
  CREATED_BY: string
  DELETED_BY: string
  ID: number
  PROGRAM_ID: number
  SUBJECT_LOGO: string
  SUBJECT_NAME: string
  TYPE: string
  UPDATED_BY: string
}

interface MentorModel {
  'ID': number
  'PROGRAM_ID': number
  'SUBJECT_DESCRIPTION': string
  'SUBJECT_LOGO': string
  'SUBJECT_NAME': string
  'TYPE': string
}

interface ProgramDetailModel extends Program {
  BENEFIT: string[]
  COMPATIBLE: string[]
  MENTOR: MentorModel[]
  REQUIREMENT: string[]
  SILABUS: SilabusModel[]
  SUBJECT: SubjectModel[]
}

interface HeadlineDataModel {
  'CONTENT_DESCRIPTION': string
  'CONTENT_MEDIA': string
  'CONTENT_TITLE': string
}

interface ProgramModel {
  all: {
    output: Program[]
    total: number
  }
  banners: string[]
  bootcampList: Program[]
  programDetail: ProgramDetailModel
  programList: Program[]
  title: HeadlineDataModel
}

enum ProgramActionType {
  GetProgramList = 'Program➝ GetProgramList',
  GetBootcampList = 'Program➝GetBootcampList',
  GetProgramById = 'Program➝ GetProgramById',
  GetProgramBanner = 'Program➝ GetProgramBanner',
  GetProgramTitle = 'Program➝ GetProgramTitle'
}

type ProgramAction = {
  data: {
    output: Program[]
    total: number
  }
  type: ProgramActionType.GetProgramList
} | {
  data: HeadlineDataModel
  type: ProgramActionType.GetProgramTitle
} | {
  data: Program[]
  type: ProgramActionType.GetBootcampList
} | {
  data: ProgramDetailModel
  type: ProgramActionType.GetProgramById
} | {
  data: string[]
  type: ProgramActionType.GetProgramBanner
};

export { ProgramActionType };
export type { Program, ProgramModel, ProgramAction };
