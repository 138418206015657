// Import axios from 'axios';
import { apiFetch } from '@api/base';

import { KarirActionType } from './types';

import type { KarirAction, KarirModel } from './types';
import type { TAction, TDispatch } from '../types';

const DEFAULT_KARIR_DATA = {
  CAREER_TAG: '',
  COMPANY_NAME: '',
  CONTENT: '',
  CREATED_AT: '',
  CREATED_BY: '',
  DELETED_AT: '',
  DELETED_BY: '',
  DESCRIPTION: '',
  ID: 0,
  IMAGE: '',
  IS_HEADER: false,
  LOCATION: '',
  SALARY_MAX: '',
  SALARY_MIN: '',
  TITLE: '',
  UPDATED_AT: '',
  UPDATED_BY: '',
  WORKING_TIME: '',
  WORKING_TYPE: ''
};

export const KarirDefault: KarirModel = {
  detail: DEFAULT_KARIR_DATA,
  list: {
    output: [],
    total: 0
  }
};

export const KarirReducer = (state: KarirModel = KarirDefault, action: KarirAction) => {
  switch (action.type) {
    case KarirActionType.GetKarirList:
      return { ...state, list: action.data };
    case KarirActionType.GetKarirById:
      return { ...state, detail: action.data };

    default:
      return { ...state };
  }
};

export const KarirCommand = {
  getKarirList: (payload: unknown): TAction<KarirAction, void> => {
    return (dispatch: TDispatch<KarirAction>) => {
      return apiFetch().post('/internal/career-list/grid', payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data,
              type: KarirActionType.GetKarirList
            });
          }
        });
    };
  },
  getKarirById: (id: number): TAction<KarirAction, void> => {
    return (dispatch: TDispatch<KarirAction>) => {
      return apiFetch().post(`/internal/career-list/grid`, {
        filter: {
          filters: [{
            field: 'id',
            operator: 'eq',
            value: id.toString()
          }],
          logic: 'AND'
        },
        pageSize: 1000,
        skip: 0,
        sort: []
      }).then((response) => {
        if (response.status === 200) {
          dispatch({
            data: response.data.output[0],
            type: KarirActionType.GetKarirById
          });
        }
      });
    };
  }
};
