interface KolaborasiDataModel {
  'CREATED_AT': string
  'DELETED_AT': string
  'DESCRIPTION': string
  'ID': number
  'IMAGE': string
  'NAME': string
  'TYPE': string
  'UPDATED_AT': string
}

export interface KolaborasiModel {
  business: {
    headline: KolaborasiDataModel
    title: KolaborasiDataModel
  }
  community: {
    headline: KolaborasiDataModel
    landingPage: KolaborasiDataModel
    title: KolaborasiDataModel
  }
}

export enum KolaborasiActionType {
  GetKolaborasiCommunityHeadline = 'Kolaborasi➝GetKolaborasiCommunityHeadline',
  GetKolaborasiCommunityTitle = 'Kolaborasi➝GetKolaborasiCommunityTitle',
  GetKolaborasiBusinessHeadline = 'Kolaborasi➝GetKolaborasiBusinessHeadline',
  GetKolaborasiBusinessTitle = 'Kolaborasi➝GetKolaborasiBusinessTitle',
  GetKolaborasiLandingPageHeadline = 'Kolaborasi➝GetKolaborasiLandingPageHeadline'
}
export type KolaborasiAction = {
  data: KolaborasiDataModel
  type: KolaborasiActionType.GetKolaborasiBusinessHeadline
} | {
  data: KolaborasiDataModel
  type: KolaborasiActionType.GetKolaborasiBusinessTitle
} | {
  data: KolaborasiDataModel
  type: KolaborasiActionType.GetKolaborasiCommunityHeadline
} | {
  data: KolaborasiDataModel
  type: KolaborasiActionType.GetKolaborasiCommunityTitle
} | {
  data: KolaborasiDataModel
  type: KolaborasiActionType.GetKolaborasiLandingPageHeadline
};
