// Import axios from 'axios';
import { apiFetch } from '@api/base';

import { JobsActionType } from './types';

import type { JobsAction, JobsModel } from './types';
import type { TAction, TDispatch } from '../types';

export const JobsDefault: JobsModel = {
  jobsList: []
};

export const JobsReducer = (state: JobsModel = JobsDefault, action: JobsAction) => {
  switch (action.type) {
    case JobsActionType.GetJobs:

      return { ...state, jobsList: action.data };

    default:
      return { ...state };
  }
};

export const JobsCommand = {

  getJobsList: (): TAction<JobsAction, void> => {
    return (dispatch: TDispatch<JobsAction>) => {
      return apiFetch().get('/external/career-list/all')
        .then((response) => {
          dispatch({
            type: JobsActionType.GetJobs,
            data: response.data.output
          });
        });
    };
  }

};
