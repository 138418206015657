export interface EventProps {
  CREATED_AT: string
  CREATED_BY: string
  DELETED_AT: string
  DELETED_BY: string
  DESCRIPTION: string
  DUE_DATE: string
  EVENT_NAME: string
  EVENT_POSTER: string
  EVENT_VENUE: string
  ID: number
  IS_FREE: boolean
  TIME: string
  UPDATED_AT: string
  UPDATED_BY: string
}

export interface EventRegistrationModel {
  _education: string
  _email: string
  _event_id: number
  _name: string
  _phone: string
  _school: string
}

export interface EventModel {
  eventDetail: EventProps
  eventExpired: EventProps[]
  eventList: EventProps[]
}

export enum EventActionType {
  GetEventList = 'Event➝GetEventList',
  GetEventById = 'Event➝GetEventById',
  GetEventExpired = 'Event➝GetEventExpired'
}
export type EventAction =
  {
    data: EventProps
    type: EventActionType.GetEventById
  } | {
    data: EventProps[]
    type: EventActionType.GetEventExpired
  } | {
    data: EventProps[]
    type: EventActionType.GetEventList
  };
